import React from 'react';

const LogoIconWhite = () => (
  <svg
    width="190"
    height="55"
    viewBox="0 0 190 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M56.2771 34.5269V18.7641C56.2773 18.662 56.3051 18.5618 56.3576 18.4744C56.4101 18.3871 56.4853 18.3158 56.5751 18.2683C56.7842 18.1481 57.0209 18.085 57.2617 18.085C57.5025 18.085 57.7391 18.1481 57.9483 18.2683C58.0366 18.3164 58.11 18.3882 58.1603 18.4757C58.2106 18.5632 58.2359 18.663 58.2333 18.7641V25.5233H64.5554V18.7641C64.5529 18.663 64.5782 18.5632 64.6285 18.4757C64.6788 18.3882 64.7521 18.3164 64.8404 18.2683C65.0496 18.1481 65.2863 18.085 65.5271 18.085C65.7679 18.085 66.0045 18.1481 66.2137 18.2683C66.302 18.3164 66.3754 18.3882 66.4257 18.4757C66.476 18.5632 66.5012 18.663 66.4987 18.7641V34.5269C66.5017 34.6316 66.4769 34.7352 66.4268 34.827C66.3767 34.9187 66.3031 34.9953 66.2137 35.0488C66.0045 35.169 65.7679 35.2321 65.5271 35.2321C65.2863 35.2321 65.0496 35.169 64.8404 35.0488C64.751 34.9953 64.6775 34.9187 64.6274 34.827C64.5772 34.7352 64.5524 34.6316 64.5554 34.5269V27.1674H58.2981V34.5269C58.3011 34.6316 58.2763 34.7352 58.2262 34.827C58.1761 34.9187 58.1025 34.9953 58.0131 35.0488C57.8039 35.169 57.5673 35.2321 57.3265 35.2321C57.0857 35.2321 56.849 35.169 56.6398 35.0488C56.5368 35.0049 56.4482 34.9326 56.384 34.8403C56.3199 34.748 56.2828 34.6394 56.2771 34.5269Z"
      fill="white"
    />
    <path
      d="M68.8048 32.3343C68.7474 31.8077 68.8506 31.2759 69.1005 30.8098C69.3504 30.3436 69.7353 29.9651 70.2039 29.7246C71.4831 29.1754 72.8705 28.9298 74.2589 29.0069H75.373V28.511C75.3918 27.7723 75.1687 27.0478 74.7382 26.4494C74.5126 26.1584 74.2203 25.9268 73.8863 25.7745C73.5524 25.6223 73.1868 25.5538 72.8208 25.5751C72.2276 25.5616 71.6389 25.682 71.0978 25.9274C70.8335 26.0623 70.5609 26.18 70.2816 26.2797C70.1735 26.2798 70.0672 26.2508 69.9739 26.1957C69.8805 26.1407 69.8034 26.0616 69.7505 25.9666C69.6273 25.7665 69.5601 25.5365 69.5561 25.3011C69.5561 24.9357 69.9059 24.6225 70.6185 24.3485C71.3332 24.07 72.0933 23.9284 72.8597 23.931C73.4823 23.8806 74.1082 23.9717 74.6912 24.1975C75.2742 24.4234 75.7994 24.7782 76.228 25.2358C76.9644 26.1525 77.3464 27.3066 77.3033 28.4849V34.4873C77.3068 34.5874 77.2848 34.6866 77.2394 34.7757C77.1941 34.8648 77.1269 34.9407 77.0442 34.9962C76.8413 35.1321 76.6012 35.2006 76.3576 35.1919C76.1303 35.1996 75.9069 35.1307 75.7228 34.9962C75.6438 34.9431 75.5789 34.8713 75.5338 34.7872C75.4886 34.703 75.4646 34.6091 75.4637 34.5134V33.6C75.0443 34.1389 74.5125 34.5784 73.9058 34.8873C73.2991 35.1963 72.6324 35.3671 71.9528 35.3877C71.1246 35.4194 70.3149 35.1352 69.6857 34.5917C69.387 34.3009 69.1538 33.9488 69.0018 33.5594C68.8498 33.1699 68.7826 32.7522 68.8048 32.3343ZM70.761 32.1125C70.7481 32.3525 70.7852 32.5926 70.87 32.8174C70.9548 33.0421 71.0853 33.2465 71.2533 33.4173C71.6039 33.7488 72.068 33.9311 72.5488 33.9262C73.2539 33.8991 73.9256 33.6165 74.4402 33.1303C74.7045 32.9445 74.9242 32.7017 75.0833 32.4195C75.2424 32.1373 75.337 31.8229 75.36 31.4992V30.2596H74.5439C74.1034 30.2596 73.7147 30.2596 73.3909 30.2596C73.0474 30.2891 72.7058 30.337 72.3674 30.4031C72.0649 30.4506 71.7745 30.557 71.5124 30.7163C71.2829 30.843 71.0941 31.0331 70.9683 31.2643C70.8186 31.5207 70.7466 31.8154 70.761 32.1125Z"
      fill="white"
    />
    <path
      d="M79.5704 30.8729V28.5241C79.5169 27.3182 79.9409 26.1401 80.7494 25.2489C81.1988 24.7974 81.7386 24.4475 82.3328 24.2225C82.927 23.9975 83.562 23.9026 84.1954 23.944C85.0369 23.9207 85.8718 24.0996 86.631 24.466C87.2917 24.8053 87.6285 25.1837 87.6285 25.5751C87.6165 25.8093 87.5302 26.0334 87.3824 26.2145C87.3188 26.3085 87.234 26.3858 87.1348 26.4403C87.0357 26.4947 86.9252 26.5247 86.8124 26.5277C86.5306 26.4287 86.2611 26.2973 86.0091 26.1362C85.4968 25.8564 84.9205 25.7169 84.3379 25.7317C83.9564 25.7053 83.5734 25.7551 83.211 25.8782C82.8486 26.0014 82.5139 26.1955 82.2262 26.4494C81.9738 26.7262 81.7794 27.0514 81.6547 27.4057C81.5301 27.76 81.4777 28.1359 81.5008 28.5111V30.8337C81.5008 32.6866 82.4594 33.6131 84.3638 33.6131C84.9549 33.6294 85.5399 33.49 86.061 33.2086C86.3233 33.0346 86.6062 32.8943 86.903 32.791C87.0223 32.7914 87.14 32.8185 87.2476 32.8704C87.3551 32.9224 87.4499 32.9978 87.5249 33.0911C87.6714 33.272 87.7535 33.4971 87.7581 33.7305C87.7581 34.122 87.4212 34.5004 86.7346 34.8527C85.9548 35.242 85.0912 35.4303 84.2213 35.4007C83.6059 35.4656 82.9838 35.3935 82.3991 35.1895C81.8144 34.9855 81.2814 34.6546 80.8377 34.2201C80.394 33.7857 80.0505 33.2583 79.8315 32.6754C79.6126 32.0925 79.5234 31.4682 79.5704 30.8468V30.8729Z"
      fill="white"
    />
    <path
      d="M89.7014 34.5266V18.0853C89.7011 17.9895 89.7247 17.8951 89.7699 17.8107C89.8152 17.7264 89.8807 17.6548 89.9605 17.6025C90.1634 17.4666 90.4035 17.3982 90.6471 17.4068C90.8949 17.3963 91.1397 17.4648 91.3467 17.6025C91.433 17.6496 91.5051 17.7194 91.5552 17.8044C91.6054 17.8894 91.6318 17.9865 91.6317 18.0853V29.0592L96.9433 24.2312C97.0595 24.1326 97.2059 24.0773 97.3579 24.0747C97.6044 24.0795 97.8402 24.1772 98.0186 24.3487C98.1127 24.4268 98.1891 24.5242 98.2427 24.6345C98.2963 24.7448 98.3259 24.8653 98.3295 24.9881C98.3316 25.0769 98.3141 25.1652 98.2783 25.2464C98.2425 25.3277 98.1891 25.3999 98.1222 25.4578L94.8834 28.3416L98.77 33.9916C98.8394 34.0964 98.8797 34.2181 98.8866 34.3439C98.8794 34.4737 98.8444 34.6004 98.7841 34.7153C98.7237 34.8302 98.6393 34.9305 98.5368 35.0094C98.3424 35.2016 98.0835 35.3133 97.8113 35.3226C97.7044 35.3239 97.599 35.2969 97.5058 35.2442C97.4125 35.1915 97.3347 35.1151 97.2801 35.0225L93.575 29.5551L91.6317 31.3167V34.5527C91.6233 34.6512 91.592 34.7462 91.5402 34.8301C91.4884 34.914 91.4177 34.9844 91.3337 35.0355C91.1456 35.1719 90.9175 35.2408 90.686 35.2312C90.4447 35.2379 90.2081 35.1645 90.0123 35.0225C89.9235 34.9726 89.8485 34.901 89.7942 34.8144C89.7399 34.7278 89.7079 34.6288 89.7014 34.5266Z"
      fill="white"
    />
    <path
      d="M100.83 34.5528V18.79C100.82 18.6914 100.835 18.5918 100.873 18.5005C100.912 18.4092 100.973 18.3293 101.05 18.2681C101.231 18.138 101.449 18.0695 101.672 18.0724H105.714C106.928 18.0103 108.132 18.3147 109.173 18.9466C109.635 19.3178 109.996 19.801 110.223 20.3509C110.449 20.9008 110.534 21.4996 110.468 22.0913C110.496 22.9393 110.318 23.7813 109.95 24.5445C109.638 25.1642 109.12 25.6538 108.486 25.9276C109.218 26.2178 109.839 26.734 110.261 27.4021C110.709 28.1945 110.916 29.1019 110.857 30.0119V30.4294C110.913 31.1042 110.826 31.7832 110.603 32.4219C110.38 33.0605 110.025 33.6444 109.562 34.1352C108.633 34.9176 107.44 35.3101 106.232 35.2313H101.711C101.483 35.2432 101.258 35.1739 101.076 35.0356C101 34.9802 100.938 34.9077 100.896 34.8238C100.853 34.7399 100.83 34.6471 100.83 34.5528ZM102.786 25.1317H105.688C106.455 25.1987 107.218 24.9597 107.813 24.4662C108.06 24.1829 108.248 23.8515 108.364 23.4925C108.48 23.1334 108.521 22.7543 108.486 22.3784C108.486 20.7082 107.528 19.8731 105.623 19.8731H102.786V25.1317ZM102.786 33.4567H105.921C107.916 33.4567 108.901 32.3867 108.901 30.2337V29.8422C108.941 29.4198 108.895 28.9937 108.766 28.5898C108.636 28.1859 108.427 27.8129 108.149 27.4935C107.529 26.9677 106.73 26.7057 105.921 26.7627H102.786V33.4567Z"
      fill="white"
    />
    <path
      d="M112.813 30.873V28.4068C112.81 27.8186 112.93 27.2364 113.167 26.6987C113.404 26.1611 113.751 25.6802 114.187 25.2881C115.049 24.4396 116.202 23.9569 117.407 23.9399C118.613 23.9229 119.779 24.3729 120.664 25.1968C121.086 25.5843 121.42 26.0588 121.643 26.5879C121.867 27.117 121.975 27.6884 121.96 28.2632C121.973 28.6393 121.921 29.0148 121.804 29.3724C121.71 29.6219 121.531 29.8298 121.299 29.9596C121.102 30.0734 120.887 30.1529 120.664 30.1944C120.405 30.2109 120.146 30.2109 119.887 30.1944H114.757V30.8991C114.74 31.2911 114.814 31.6817 114.974 32.0397C115.134 32.3976 115.374 32.713 115.676 32.9607C116.204 33.3516 116.819 33.6059 117.468 33.7015C118.116 33.797 118.778 33.7309 119.395 33.5088C119.712 33.3802 120.02 33.2275 120.314 33.0521C120.475 32.9259 120.668 32.8488 120.871 32.8303C120.98 32.8302 121.087 32.8563 121.184 32.9062C121.281 32.9561 121.365 33.0284 121.428 33.1173C121.574 33.2835 121.657 33.4961 121.662 33.7176C121.662 34.0829 121.312 34.4483 120.612 34.8137C119.807 35.2129 118.918 35.4099 118.021 35.3878C116.663 35.4369 115.337 34.9712 114.303 34.0829C113.835 33.6918 113.459 33.2012 113.201 32.6462C112.943 32.0912 112.811 31.4856 112.813 30.873ZM114.757 28.9026H119.058C119.339 28.9294 119.622 28.8751 119.874 28.746C119.954 28.67 120.014 28.5758 120.05 28.4713C120.086 28.3669 120.097 28.2553 120.081 28.1458C120.081 27.4537 119.808 26.7899 119.322 26.3004C118.836 25.811 118.177 25.5361 117.49 25.5361C117.144 25.5272 116.799 25.5884 116.476 25.7161C116.153 25.8439 115.859 26.0356 115.612 26.2798C115.357 26.5065 115.155 26.7861 115.019 27.0993C114.882 27.4124 114.815 27.7517 114.821 28.0936L114.757 28.9026Z"
      fill="white"
    />
    <path
      d="M123.346 32.3346C123.288 31.808 123.392 31.2763 123.642 30.8101C123.891 30.344 124.276 29.9655 124.745 29.7249C126.02 29.1777 127.403 28.9322 128.787 29.0072H129.914V28.5114C129.928 27.7713 129.701 27.0469 129.266 26.4497C129.042 26.157 128.75 25.9242 128.416 25.7718C128.082 25.6194 127.715 25.5519 127.349 25.5754C126.766 25.5813 126.191 25.7148 125.665 25.9669C125.403 26.1071 125.13 26.2249 124.849 26.3192C124.742 26.3197 124.637 26.2908 124.546 26.2355C124.455 26.1803 124.38 26.101 124.33 26.0061C124.194 25.8112 124.122 25.5787 124.123 25.3406C124.123 24.9752 124.473 24.662 125.185 24.388C125.905 24.1094 126.669 23.9678 127.44 23.9705C128.06 23.9202 128.684 24.0114 129.265 24.2373C129.846 24.4632 130.369 24.818 130.795 25.2753C131.535 26.1907 131.921 27.3446 131.883 28.5244V34.5268C131.884 34.6278 131.86 34.7275 131.812 34.8164C131.764 34.9054 131.695 34.9808 131.611 35.0357C131.388 35.1905 131.117 35.2599 130.847 35.2314C130.624 35.2392 130.404 35.1701 130.225 35.0357C130.144 34.9843 130.078 34.9129 130.033 34.8284C129.987 34.7439 129.964 34.649 129.966 34.5529V33.6395C129.543 34.1774 129.008 34.616 128.4 34.9247C127.791 35.2335 127.123 35.4049 126.442 35.4272C125.614 35.4576 124.805 35.1735 124.175 34.6312C123.88 34.3318 123.653 33.9714 123.509 33.5748C123.366 33.1783 123.31 32.7551 123.346 32.3346ZM125.302 32.1128C125.287 32.3519 125.323 32.5915 125.405 32.8162C125.488 33.0408 125.616 33.2457 125.781 33.4177C126.141 33.7279 126.604 33.8909 127.077 33.8744C127.786 33.8458 128.461 33.5635 128.981 33.0784C129.243 32.8901 129.461 32.6466 129.62 32.365C129.778 32.0833 129.875 31.7702 129.901 31.4473V30.2077H129.085C128.627 30.2077 128.239 30.2077 127.919 30.2077C127.58 30.2381 127.243 30.286 126.908 30.3512C126.603 30.4025 126.309 30.5086 126.04 30.6644C125.817 30.7987 125.63 30.9871 125.496 31.2125C125.341 31.4853 125.273 31.7997 125.302 32.1128Z"
      fill="white"
    />
    <path
      d="M134.5 34.5269V24.7926C134.5 24.6968 134.523 24.6023 134.569 24.518C134.614 24.4337 134.68 24.3621 134.759 24.3098C134.962 24.1739 135.202 24.1054 135.446 24.1141C135.671 24.109 135.891 24.1828 136.068 24.3229C136.149 24.3745 136.217 24.4456 136.264 24.5298C136.311 24.6141 136.338 24.7088 136.34 24.8057V25.7843C136.603 25.2424 137.017 24.7891 137.532 24.4794C138.111 24.1176 138.781 23.9318 139.462 23.9445C140.006 23.9416 140.545 24.0566 141.041 24.2817C141.537 24.5069 141.98 24.8369 142.338 25.2493C143.137 26.1123 143.574 27.2533 143.556 28.4332V34.4747C143.556 34.5804 143.529 34.6844 143.477 34.776C143.424 34.8677 143.349 34.9438 143.258 34.9966C143.05 35.1208 142.813 35.184 142.571 35.1793C142.339 35.1793 142.111 35.1162 141.911 34.9966C141.821 34.9432 141.748 34.8666 141.697 34.7748C141.647 34.683 141.622 34.5794 141.626 34.4747V28.4984C141.618 27.7879 141.334 27.1085 140.835 26.6064C140.61 26.347 140.333 26.1375 140.024 25.9912C139.714 25.8449 139.377 25.7649 139.035 25.7564C138.693 25.7478 138.353 25.8108 138.036 25.9414C137.719 26.0719 137.433 26.2673 137.195 26.515C136.941 26.7763 136.742 27.0868 136.611 27.4277C136.479 27.7687 136.418 28.1329 136.43 28.4984V34.5269C136.423 34.6256 136.392 34.7211 136.34 34.8052C136.289 34.8892 136.217 34.9594 136.133 35.0097C135.94 35.1355 135.715 35.2025 135.485 35.2025C135.255 35.2025 135.03 35.1355 134.837 35.0097C134.745 34.9642 134.666 34.8962 134.607 34.8118C134.548 34.7274 134.511 34.6294 134.5 34.5269Z"
      fill="white"
    />
    <path
      d="M146.238 39.9025V24.7922C146.235 24.6959 146.257 24.6005 146.303 24.5158C146.348 24.431 146.415 24.3598 146.497 24.3094C146.69 24.1747 146.922 24.1061 147.157 24.1136C147.393 24.1022 147.626 24.1711 147.818 24.3094C147.898 24.3646 147.963 24.4386 148.009 24.525C148.054 24.6114 148.077 24.7076 148.077 24.8052V25.7839C148.39 25.2321 148.843 24.7736 149.389 24.4551C149.935 24.1365 150.555 23.9692 151.186 23.9701C151.747 23.9582 152.304 24.0686 152.818 24.2939C153.332 24.5191 153.792 24.8538 154.166 25.275C154.965 26.1379 155.402 27.2789 155.384 28.4588V30.8467C155.369 32.0314 154.906 33.1657 154.088 34.0176C153.738 34.442 153.301 34.7853 152.807 35.024C152.313 35.2627 151.773 35.3912 151.225 35.4007C150.562 35.4041 149.91 35.2187 149.347 34.8657C148.802 34.5613 148.353 34.1093 148.051 33.5609V39.8894C148.041 39.9873 148.009 40.0816 147.957 40.1652C147.906 40.2489 147.836 40.3196 147.753 40.3722C147.555 40.5208 147.314 40.5988 147.067 40.5941C146.826 40.597 146.59 40.524 146.393 40.3853C146.335 40.3202 146.291 40.2436 146.265 40.1605C146.238 40.0773 146.229 39.9894 146.238 39.9025ZM148.168 31.4992C148.263 32.0206 148.549 32.4867 148.971 32.804C149.21 33.0562 149.498 33.2557 149.817 33.39C150.136 33.5243 150.479 33.5905 150.825 33.5844C151.171 33.5783 151.511 33.5 151.826 33.3544C152.14 33.2089 152.421 32.9992 152.65 32.7388C153.159 32.2259 153.447 31.5325 153.454 30.8076V28.4849C153.444 27.7722 153.155 27.0921 152.65 26.5929C152.407 26.3289 152.113 26.1184 151.785 25.9747C151.457 25.831 151.103 25.7571 150.746 25.7578C150.411 25.7552 150.078 25.8208 149.769 25.9509C149.459 26.081 149.179 26.2727 148.945 26.5146C148.686 26.77 148.483 27.0773 148.349 27.4167C148.215 27.7561 148.153 28.12 148.168 28.4849V31.4992Z"
      fill="white"
    />
    <path
      d="M157.288 30.8731V28.4852C157.277 27.8975 157.387 27.3138 157.61 26.7705C157.833 26.2272 158.164 25.736 158.584 25.3274C159.008 24.9045 159.512 24.5713 160.066 24.3472C160.62 24.1231 161.213 24.0128 161.81 24.0226C162.408 24.0139 163.003 24.1248 163.559 24.3488C164.114 24.5727 164.621 24.9053 165.048 25.3274C165.486 25.7291 165.834 26.219 166.071 26.7654C166.307 27.3118 166.427 27.9022 166.422 28.4983V30.8862C166.42 31.4824 166.297 32.072 166.058 32.6177C165.819 33.1634 165.471 33.6535 165.035 34.057C164.221 34.9045 163.11 35.3965 161.939 35.4271C161.338 35.4295 160.743 35.308 160.191 35.0701C159.638 34.8322 159.14 34.483 158.726 34.0439C158.283 33.643 157.927 33.1546 157.68 32.6091C157.433 32.0636 157.299 31.4727 157.288 30.8731ZM159.219 30.8731C159.22 31.6028 159.509 32.3021 160.022 32.8174C160.253 33.0819 160.539 33.2933 160.858 33.4374C161.178 33.5814 161.524 33.6548 161.874 33.6525C162.231 33.6545 162.585 33.5799 162.911 33.4337C163.237 33.2874 163.528 33.0729 163.766 32.8043C164.271 32.2901 164.555 31.5964 164.556 30.8731V28.4852C164.552 27.7741 164.267 27.0937 163.766 26.5932C163.524 26.3308 163.232 26.1213 162.906 25.9776C162.581 25.8339 162.23 25.7592 161.874 25.758C161.523 25.7572 161.175 25.8312 160.853 25.9751C160.532 26.119 160.244 26.3296 160.009 26.5932C159.512 27.0969 159.229 27.7752 159.219 28.4852V30.8731Z"
      fill="white"
    />
    <path
      d="M168.767 31.6954V19.5732C168.773 19.4726 168.805 19.3753 168.859 19.2908C168.914 19.2062 168.989 19.1372 169.077 19.0904C169.269 18.9614 169.495 18.8932 169.725 18.8947C169.964 18.8912 170.199 18.9593 170.399 19.0904C170.487 19.1372 170.563 19.2062 170.617 19.2908C170.672 19.3753 170.703 19.4726 170.71 19.5732V24.075H173.599C173.682 24.0744 173.764 24.0958 173.837 24.137C173.91 24.1782 173.971 24.2377 174.013 24.3098C174.128 24.4687 174.187 24.6615 174.182 24.8579C174.188 25.0463 174.128 25.2309 174.013 25.3798C173.967 25.4478 173.905 25.5035 173.833 25.5422C173.761 25.5808 173.681 25.6012 173.599 25.6017H170.671V31.604C170.644 31.8538 170.668 32.1066 170.741 32.3467C170.815 32.5868 170.936 32.8093 171.098 33.0002C171.531 33.3215 172.066 33.4703 172.601 33.4178H173.314C173.429 33.4089 173.545 33.4267 173.653 33.4696C173.761 33.5126 173.857 33.5796 173.936 33.6657C174.092 33.8464 174.179 34.0782 174.179 34.3181C174.179 34.5581 174.092 34.7899 173.936 34.9706C173.859 35.0565 173.765 35.1245 173.66 35.1696C173.555 35.2147 173.441 35.2359 173.327 35.2316H172.614C170.062 35.2316 168.767 34.0572 168.767 31.6954Z"
      fill="white"
    />
    <path
      d="M21.2205 18.3332C20.9163 18.3323 20.6206 18.2315 20.3784 18.0462C20.0797 17.8225 19.8798 17.49 19.8213 17.1197C19.7901 16.9362 19.7983 16.7482 19.8452 16.5681C19.8921 16.3881 19.9767 16.2202 20.0934 16.0758C20.1979 15.9214 20.3341 15.7914 20.4928 15.6946C20.6515 15.5978 20.8289 15.5365 21.0132 15.5147C21.1944 15.4815 21.3806 15.4876 21.5594 15.5326C21.7381 15.5776 21.9052 15.6605 22.0496 15.7757C22.3494 15.9996 22.549 16.3338 22.6049 16.7055C22.6607 17.0771 22.5682 17.4559 22.3476 17.7591C22.1228 18.0569 21.7939 18.2575 21.4278 18.3202L21.2205 18.3332ZM21.2205 16.389H21.1428C21.0038 16.4104 20.8784 16.4853 20.793 16.5978C20.7277 16.7075 20.7046 16.8375 20.7282 16.9631C20.7358 17.0333 20.7583 17.1011 20.794 17.1619C20.8298 17.2226 20.878 17.275 20.9355 17.3154C20.9907 17.3565 21.0536 17.3857 21.1205 17.4014C21.1873 17.4171 21.2566 17.4189 21.3241 17.4068C21.3918 17.3981 21.4568 17.375 21.5149 17.339C21.573 17.303 21.6228 17.2549 21.661 17.198C21.7035 17.1439 21.7347 17.0816 21.7525 17.0149C21.7704 16.9482 21.7746 16.8786 21.7649 16.8102C21.7552 16.7419 21.7318 16.6762 21.6961 16.6173C21.6605 16.5583 21.6132 16.5073 21.5573 16.4673C21.4586 16.3999 21.3385 16.372 21.2205 16.389Z"
      fill="white"
    />
    <path
      d="M25.5864 15.6583C25.2879 15.6592 24.9971 15.5631 24.7572 15.3842C24.4497 15.161 24.2428 14.8239 24.1821 14.4471C24.1214 14.0702 24.2118 13.6845 24.4334 13.3747C24.655 13.065 24.9897 12.8566 25.3638 12.7955C25.738 12.7343 26.1209 12.8253 26.4285 13.0485C26.7249 13.2799 26.9239 13.6154 26.9855 13.988C27.0127 14.1715 27.0026 14.3587 26.9558 14.5381C26.9091 14.7175 26.8266 14.8855 26.7135 15.0319C26.609 15.1863 26.4728 15.3163 26.3141 15.4131C26.1554 15.5099 25.9779 15.5713 25.7937 15.593L25.5864 15.6583ZM25.5864 13.714H25.5086C25.3718 13.7406 25.2509 13.8203 25.1718 13.9358C25.1293 13.99 25.0981 14.0523 25.0802 14.119C25.0623 14.1856 25.0581 14.2553 25.0678 14.3236C25.0775 14.392 25.1009 14.4576 25.1366 14.5166C25.1723 14.5756 25.2196 14.6266 25.2755 14.6666C25.387 14.7501 25.5261 14.7874 25.6641 14.771C25.8031 14.7495 25.9285 14.6747 26.0139 14.5622C26.0935 14.4485 26.126 14.3081 26.1046 14.1707C26.0978 14.1003 26.0757 14.0322 26.0399 13.9713C26.004 13.9104 25.9554 13.8582 25.8973 13.8184C25.8088 13.7468 25.6998 13.7056 25.5864 13.701V13.714Z"
      fill="white"
    />
    <path
      d="M21.1558 39.5894C20.8205 39.5817 20.4984 39.4562 20.2453 39.2346C19.9921 39.013 19.8239 38.7092 19.7696 38.3759C19.7175 38.0031 19.8104 37.6245 20.0287 37.3189C20.1373 37.1698 20.2748 37.0443 20.4329 36.9501C20.591 36.8559 20.7664 36.795 20.9485 36.7709C21.1318 36.7414 21.3192 36.7493 21.4994 36.7941C21.6797 36.839 21.8492 36.9198 21.9978 37.0319C22.1467 37.1426 22.2723 37.282 22.3673 37.4419C22.4623 37.6018 22.5249 37.7792 22.5514 37.9636C22.5779 38.1481 22.5678 38.336 22.5217 38.5166C22.4756 38.6971 22.3944 38.8666 22.2828 39.0153C22.1514 39.1918 21.9814 39.3354 21.7859 39.435C21.5904 39.5346 21.3748 39.5874 21.1558 39.5894ZM21.1558 37.6321H21.078C21.0083 37.6398 20.9411 37.6624 20.8807 37.6984C20.8204 37.7344 20.7684 37.783 20.7282 37.8409C20.6838 37.8947 20.6516 37.9575 20.6337 38.0251C20.6158 38.0927 20.6127 38.1634 20.6246 38.2323C20.6459 38.3724 20.7202 38.4986 20.8319 38.5847C20.9447 38.6648 21.0841 38.6976 21.2205 38.676C21.2902 38.6683 21.3575 38.6457 21.4178 38.6097C21.4781 38.5737 21.5301 38.5251 21.5703 38.4672C21.6521 38.3528 21.6858 38.2105 21.6639 38.0712C21.6421 37.9319 21.5665 37.8069 21.4537 37.7235C21.3657 37.6637 21.2619 37.6319 21.1558 37.6321Z"
      fill="white"
    />
    <path
      d="M28.7863 22.7176C28.4561 22.715 28.1366 22.6001 27.8794 22.3914C27.5955 22.1484 27.4185 21.8021 27.387 21.4281C27.3554 21.0542 27.4719 20.6828 27.711 20.395C27.8299 20.2521 27.9759 20.1345 28.1406 20.0494C28.3052 19.9642 28.4851 19.9131 28.6697 19.8991C28.8535 19.8767 29.04 19.8942 29.2166 19.9505C29.3931 20.0067 29.5556 20.1005 29.6932 20.2254C29.838 20.3434 29.9575 20.4898 30.0444 20.6558C30.1312 20.8218 30.1836 21.0039 30.1984 21.191C30.2244 21.5657 30.1035 21.9358 29.8616 22.2218C29.7442 22.3632 29.6003 22.4799 29.4379 22.565C29.2756 22.6501 29.0982 22.702 28.9159 22.7176H28.7863ZM28.7863 20.7734C28.7156 20.7786 28.6468 20.7987 28.5843 20.8324C28.5218 20.8661 28.467 20.9127 28.4236 20.9691C28.3793 21.0207 28.3462 21.081 28.3261 21.1461C28.306 21.2112 28.2995 21.2798 28.307 21.3475C28.3184 21.4883 28.3834 21.6192 28.4883 21.7129C28.5974 21.8023 28.7371 21.8445 28.877 21.8303C29.0129 21.8182 29.1386 21.7526 29.2268 21.6477C29.273 21.5943 29.3083 21.5322 29.3305 21.465C29.3528 21.3978 29.3615 21.3268 29.3563 21.2562C29.3417 21.1201 29.277 20.9944 29.175 20.9039C29.1243 20.8577 29.065 20.8223 29.0004 20.7999C28.9358 20.7774 28.8674 20.7684 28.7993 20.7734H28.7863Z"
      fill="white"
    />
    <path
      d="M39.565 20.8777C39.1224 20.2744 38.6187 19.719 38.0622 19.2205C36.8865 18.1302 35.4924 17.3064 33.9739 16.8046C32.4553 16.3029 30.8477 16.1349 29.2592 16.312C27.6708 16.4891 26.1385 17.0071 24.7656 17.8311C23.3927 18.6551 22.2112 19.7661 21.3007 21.0889C20.3902 22.4118 19.7719 23.916 19.4874 25.4999C19.2029 27.0839 19.2588 28.7109 19.6515 30.2713C20.0441 31.8316 20.7643 33.289 21.7635 34.5453C22.7627 35.8016 24.0177 36.8275 25.4439 37.5539L24.6148 40.4507L25.9103 40.816L26.8819 37.3842C26.9253 37.2279 26.9127 37.0613 26.8464 36.9134C26.7801 36.7655 26.6643 36.6458 26.5192 36.5752C25.2059 35.9967 24.0338 35.1364 23.0849 34.0544C22.136 32.9724 21.433 31.6947 21.0252 30.3107C20.6174 28.9267 20.5145 27.4696 20.7238 26.0414C20.933 24.6131 21.4493 23.2481 22.2367 22.0417C23.024 20.8353 24.0634 19.8166 25.2822 19.0568C26.501 18.2969 27.8697 17.8143 29.2929 17.6425C30.7161 17.4708 32.1594 17.6141 33.5219 18.0625C34.8844 18.5108 36.1332 19.2534 37.1812 20.2383L37.4274 20.4732C37.7545 20.4349 38.0849 20.4349 38.412 20.4732C38.8151 20.546 39.2042 20.6825 39.565 20.8777Z"
      fill="white"
    />
    <path
      d="M39.565 20.878C40.2828 21.8106 40.8351 22.8613 41.1973 23.9835C41.705 25.2438 41.8661 26.6186 41.6637 27.9634C41.1747 31.5111 39.3117 34.7198 36.4816 36.8886C35.1861 37.7498 32.5951 38.7024 31.1441 37.5933C30.5944 37.1905 30.1591 36.6497 29.8818 36.0246C29.6045 35.3995 29.4947 34.7121 29.5636 34.031C29.6279 33.3291 29.8389 32.6488 30.1828 32.0349C30.5266 31.4209 30.9955 30.8872 31.5587 30.4687C34.2274 28.3548 34.2145 26.6846 34.2145 25.8756C34.2533 24.4916 34.8084 23.1731 35.7691 22.1828C36.3031 21.7259 36.9861 21.4842 37.6865 21.5043H38.2565H38.399C38.1039 21.114 37.779 20.7476 37.4274 20.4082C36.5715 20.4388 35.7524 20.7661 35.1084 21.3347C33.9038 22.551 33.2241 24.1968 33.2169 25.9147C33.2169 26.528 33.2169 27.7937 30.9498 29.5944C30.2699 30.1047 29.7057 30.755 29.2947 31.5019C28.8837 32.2489 28.6353 33.0754 28.566 33.9266C28.482 34.7831 28.6219 35.6468 28.9719 36.432C29.3219 37.2172 29.8698 37.8964 30.5611 38.4023C31.2435 38.8826 32.0609 39.1296 32.8931 39.1069C34.3909 39.049 35.8437 38.5748 37.0905 37.7368C40.1372 35.3747 42.142 31.9029 42.6742 28.0678C43.1406 23.9835 41.3398 21.7261 39.565 20.878Z"
      fill="white"
    />
    <path
      d="M41.1973 23.9836C40.8351 22.8613 40.2828 21.8106 39.565 20.878C39.2019 20.6964 38.8129 20.5731 38.412 20.5126C38.0849 20.4743 37.7545 20.4743 37.4274 20.5126C37.779 20.852 38.1039 21.2185 38.399 21.6087C39.0184 21.754 39.5949 22.0449 40.0813 22.4577C40.5676 22.8705 40.9501 23.3933 41.1973 23.9836Z"
      fill="white"
    />
    <path
      d="M16.8417 31.7079C16.7289 31.7101 16.6174 31.6832 16.5178 31.6297C16.436 31.5842 16.364 31.5227 16.3061 31.4487C16.2483 31.3747 16.2057 31.2899 16.181 31.1991C15.8805 29.9914 15.7283 28.7513 15.7276 27.5063C15.7136 25.5729 16.0783 23.6558 16.8008 21.8646C17.5233 20.0733 18.5893 18.4432 19.938 17.0674L20.8967 18.0069C18.4099 20.5131 17.0121 23.9108 17.0101 27.4541C17.0155 28.3302 17.1023 29.204 17.2692 30.0638L19.938 29.1896L20.3525 30.4944L16.9842 31.6036L16.8417 31.7079Z"
      fill="white"
    />
    <path
      d="M30.4834 42.3167C27.2684 42.3245 24.1404 41.2652 21.5833 39.3025L22.3994 38.2195C24.7162 40.0151 27.5601 40.9837 30.4834 40.9727C32.3921 40.9717 34.2785 40.5591 36.0156 39.7626C37.7528 38.9661 39.3005 37.8043 40.5547 36.3551C41.8089 34.9059 42.7405 33.2031 43.2868 31.361C43.8332 29.519 43.9816 27.5805 43.7221 25.6759C43.4626 23.7713 42.8013 21.9447 41.7825 20.319C40.7638 18.6933 39.4112 17.3061 37.8158 16.2508C36.2205 15.1954 34.4192 14.4964 32.5333 14.2006C30.6473 13.9049 28.7203 14.0193 26.8819 14.5362L26.5192 13.2313C27.8092 12.8658 29.1433 12.6814 30.4834 12.6833C32.452 12.6244 34.4122 12.9641 36.2481 13.6823C38.0839 14.4004 39.7579 15.4824 41.171 16.864C42.5841 18.2457 43.7075 19.899 44.4746 21.726C45.2418 23.5529 45.6371 25.5164 45.6371 27.5C45.6371 29.4836 45.2418 31.4471 44.4746 33.274C43.7075 35.101 42.5841 36.7543 41.171 38.136C39.7579 39.5176 38.0839 40.5996 36.2481 41.3177C34.4122 42.0359 32.452 42.3756 30.4834 42.3167Z"
      fill="white"
    />
    <path
      d="M22.1533 33.9263L21.363 32.8302L24.3298 30.6511C23.8397 29.6812 23.5865 28.6076 23.5913 27.5194C23.587 26.1351 23.9961 24.7814 24.7654 23.6342C25.5348 22.487 26.6288 21.5993 27.9054 21.0864L28.4106 22.3913C27.3744 22.805 26.4868 23.5251 25.8647 24.4568C25.2427 25.3885 24.9153 26.488 24.9257 27.6107C24.9272 28.6578 25.2188 29.6836 25.7678 30.5728C25.8595 30.716 25.8932 30.8893 25.8619 31.0568C25.8306 31.2243 25.7366 31.3734 25.5994 31.4731L22.1533 33.9263Z"
      fill="white"
    />
    <path
      d="M38.2954 21.0735C40.0314 21.3214 42.6613 23.2787 42.1819 28.0806C41.6727 31.7692 39.7411 35.1071 36.8055 37.3712C35.51 38.2194 32.6858 39.4068 30.8721 38.0628C30.2537 37.6126 29.7632 37.0071 29.4495 36.3065C29.1358 35.6059 29.0099 34.8348 29.0843 34.0699C29.1563 33.2993 29.3884 32.5525 29.7656 31.8781C30.1428 31.2037 30.6566 30.6167 31.2737 30.1553C33.8647 28.0806 33.7222 26.567 33.7352 25.9537C33.6834 24.6619 34.5254 20.5385 38.2954 21.0735Z"
      fill="white"
    />
  </svg>
);

export default LogoIconWhite;
